<template>
    <div>
        <q-form ref="myForm" greedy class="q-gutter-md add">
            <div style='margin: 0;' class="row justify-around">
                <div class="col-5">
                    <q-input
                        class="add"
                        :dense="true"
                        outlined
                        v-model="addPermissions.permissionName"
                        label="资源名称*"
                        lazy-rules
                        :rules="[required.permissionNameRules,required.permissionNamelengthRules]"
                    />
                    <q-select
                        outlined
                        v-model="useraddmodel"
                        :options="permissionType"
                        class="add"
                        label="资源类型*"
                        option-label="value"
                        color="teal"
                        :dense="dense"
                        :rules="[
                            (val) =>
                                (val && val.value != null) || '资源类型不能为空',
                        ]"
                        lazy-rules
                        options-selected-class="text-deep-orange"
                    >
                        <template v-slot:option="scope">
                            <q-item
                                v-bind="scope.itemProps"
                                v-on="scope.itemEvents"
                            >
                                <q-item-section>
                                    <q-item-label>{{
                                        scope.opt.value
                                    }}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-select>
                    <q-input
                        class="add"
                        :dense="true"
                        outlined
                        v-model="addPermissions.requestUrl"
                        label="资源请求路径"
                        lazy-rules
                        :rules="[required.kong]"
                    />

                </div>
                <div class="col-5">
                    <q-input
                        class="add"
                        :dense="true"
                        outlined
                        v-model="addPermissions.permissionValue"
                        label="资源权限值"
                        lazy-rules
                        :rules="[required.permissionValueRules]"
                    />
                    
                    <div class="add">
                        <el-select placeholder="父级资源" :value="addPermissions.valueTitle" :clearable="clearable" @clear="clearHandle" @visible-change='visiblechange'>
                            <el-option :value="addPermissions.valueTitle" :label="addPermissions.valueTitle">
                                <el-tree  id="tree-option"
                                    ref="selectTree"
                                    :accordion="accordion"
                                    :data="permissionlist"
                                    node-key="id"    
                                    :props="defaultProps"
                                    :default-expanded-keys="defaultExpandedKey"
                                    @node-click="handleNodeClick">
                                </el-tree>
                            </el-option>
                        </el-select>
                    </div>

                    <q-input
                        style='margin-top: 20px;'
                        :dense="true"
                        outlined
                        v-model="addPermissions.iconName"
                        label="图标名称"
                        lazy-rules
                        :rules="[required.iconNameLength]"
                    />

                </div>
            </div>
            <div class="addbtn">
                <el-popover
                    placement="bottom"
                    width="155"
                    v-model="visible">
                    <p>未保存的数据将被丢弃, 是否继续?</p>
                    <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="quxiao">确定</el-button>
                    </div>
                    <el-button  type="text" slot="reference">取消</el-button>
                </el-popover>
                <!-- <q-btn
                    flat
                    label="取消"
                    color="primary"
                    v-close-popup
                    @click="quxiao"
                /> -->
                <q-btn
                    class="kong"
                    label="添加"
                    color="blue-6"
                    @click="addPermissionsList"
                />
            </div>
        </q-form>
    </div>
</template>

<script>
import { Addpermission } from "@/api/permissions.js";
import { notifys } from '@/api/components/Notify.js'
import { mapState } from "vuex"

export default {
    props: {
        addPermissions: {
            type: Object,
            required: true,
        },
        permissionlist:{
            type: Array,
            required: true,
        },
        defaultExpandedKey:{
            type: Array,
            required: true,
        },
        addmodel:{
            type: [Number,String,Object]
        }
    },
    created() {},
    data() {
        return {
            visible: false,//控制取消提示
            options: [
                {
                    label: 0,
                    description: "菜单",
                },
                {
                    label: 1,
                    description: "按钮",
                },
            ], // 选择框内容
            dense: true, // 控制选择框大小
            required: {
                kong: () => {},
                permissionNameRules: (val) =>(val && val.trim().length > 0) || "资源名称不能为空",
                permissionNamelengthRules: (val) =>( val.trim().length <= 10) || "资源名称最大长度为10",
                permissionValueRules: (val) =>( val.trim().length <= 64) || "资源权限值最大长度为64",
                mustselect: (val) => val.length > 0 || "必填项,不可为空",
                roleDesc: (val) =>val.trim().length <= 20 || "长度不可大于20个字符",
                iconNameLength: (val) =>(val==null || val.trim().length <=100) || "图标名称不可大于100个字符",
            },
            accordion: true,
            clearable: true,
            
            valueId:'0',
            defaultProps: {
                children: 'children',
                label: 'permissionName'
            },
        };
    },
    components: {},
    computed: {
        ...mapState([
            'permissionType', //资源类型
        ]),
        useraddmodel:{
            get(){
                return this.addmodel
            },
            set(v){
                this.$emit("update:addmodel",v)
            }
        }
    },
    watch: {},
    methods: {
        //关闭时对话框是触发
        quxiao() {
            this.visible = false
            this.$emit("addconfirm");
            this.model=''
            this.valueId=''
            console.log(this.defaultKey);
            this.$refs.myForm.resetValidation();
        },
        addPermissionsList() {
            console.log(this.model);
            this.$refs.myForm.validate().then(async (outcome) => {
                if (outcome) {
                    try {
                        const { data: res } = await Addpermission({
                            permissionName: this.addPermissions.permissionName,
                            permissionType: this.useraddmodel.key,
                            permissionValue: this.addPermissions.permissionValue,
                            requestUrl: this.addPermissions.requestUrl,
                            pid: this.valueId,
                            iconName: this.addPermissions.iconName,
                        });
                        console.log(res);
                        if (res.code === 20000) {
                            this.$emit("addpermissionssuccess"); // 表格刷新页面
                            this.$emit("addconfirm"); // 清空表单内容
                            this.$emit("closedialog"); // 关闭对话框
                            this.model=null
                            this.$refs.myForm.resetValidation();
                            notifys('positive',res.message)
                        } else {
                            notifys('negative',res.message)
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            });
        },
         //节点被点击时回调
        handleNodeClick(node) {
            console.log(node);
            this.addPermissions.valueTitle = node.permissionName
            this.valueId = node.id
            console.log(this.valueId);
        },
        //点击清空按钮时触发
        clearHandle(){
            this.valueId = ''
            this.addPermissions.valueTitle = ''
        },
        visiblechange(){
            this.$emit('clearHandles')
        }
    },
};
</script>

<style scoped>
.kong{
  margin-left: 1.25rem !important;
}
.add {
    width: 100%;
    margin-left: 0;
    margin-top: 0.1875rem;
}
.addbtn {
    display: flex;
    justify-content: flex-end;
}
.el-select{
    width: 100%;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item{
height: auto;
max-height: 17.125rem;
padding: 0;
overflow: hidden;
overflow-y: auto;
}
.el-select-dropdown__item.selected{
font-weight: normal;
}
ul li >>>.el-tree .el-tree-node__content{
height:auto;
padding: 0 1.25rem;
}
.el-tree-node__label{
font-weight: normal;
}
.el-tree >>>.is-current .el-tree-node__label{
color: #409EFF;
font-weight: 700;
}
.el-tree >>>.is-current .el-tree-node__children .el-tree-node__label{
color:#606266;
font-weight: normal;
}
</style>
