<template>
  <div>
    <q-form ref="myForm" greedy class="q-gutter-md add">
        <div style='margin: 0;' class="row justify-around">
            <div class="col-5">
                <q-input 
                class="add" 
                :dense='true' outlined  
                v-model="editPermissionDatas.permissionName" 
                label="资源名称*" 
                lazy-rules 
                :rules="[required.permissionNameRules]"
                />

                <q-select
                    outlined 
                    v-model="editusermodel"
                    :options="permissionType"
                    class="add"
                    label="资源类型*"
                    color="teal"
                    option-label="value"
                    :dense='dense'
                    :rules="[val => {
                        console.log(val)
                        return val  && val.value != null ? true : '资源类型不能为空'
                    }]"
                    lazy-rules
                    options-selected-class="text-deep-orange"
                >
                    <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                            <q-item-section>
                                <q-item-label>{{ scope.opt.value }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>

                <q-input class="add" :dense='true' outlined  v-model="editPermissionDatas.requestUrl" label="资源请求路径" lazy-rules :rules="[required.kong]"/>

            </div>
            <div class="col-5">
                <q-input 
                    class="add" 
                    :dense='true' 
                    outlined  
                    v-model="editPermissionDatas.permissionValue" 
                    label="资源权限值" 
                    lazy-rules 
                    :rules="[required.kong]"
                />

                <div class="add">
                    <el-select placeholder="父级资源" :value="userpermissionName" :clearable="clearable" @clear="clearHandle" @visible-change='visiblechange'>
                        <el-option :value="userpermissionName" :label="userpermissionName">
                            <el-tree  id="tree-option"
                                ref="selectTree"
                                :accordion="accordion"
                                :data="permissionlist"
                                node-key="id"    
                                :props="defaultProps"
                                :default-expanded-keys="defaultExpandedKey"
                                @node-click="handleNodeClick">
                            </el-tree>
                        </el-option>
                    </el-select>
                </div>

                <q-input
                    style='margin-top: 20px;'
                    :dense="true"
                    outlined
                    v-model="editPermissionDatas.iconName"
                    label="图标名称"
                    lazy-rules
                    :rules="[required.iconNameLength]"
                />

            </div>
        </div>
        <div class="addbtn">
            <q-btn flat label="取消" color="primary" v-close-popup  @click='quxiao'/>
            <q-btn label="保存" color="blue-6" @click='addPermissionsList'/>
        </div>
    </q-form>
  </div>
</template>

<script>
import { Editpermission } from "@/api/permissions.js";
import { notifys } from '@/api/components/Notify.js'
import { mapState } from "vuex"

export default {
    props: {
        editPermissionData: {
            type: Object,
            required: true,
        },
        permissionlist: {
            type: Array,
            required: true,
        },
        defaultExpandedKey: {
            type: Array,
            required: true,
        },
        AllpermissionName: {
            type: String,
        },
        usermodel: {
            type: [Object, Number],
        },
    },
    data() {
        return {
            dense: true, // 控制选择框大小
            required: {
                kong: () => {},
                permissionNameRules: (val) =>(val && val.trim().length > 0) || "资源名称不能为空",
                permissionValueRules: (val) =>(val && val.trim().length > 0) || "资源权限值不能为空",
                mustselect: (val) => val.length > 0 || "必填项,不可为空",
                roleDesc: (val) =>val.trim().length <= 20 || "长度不可大于20个字符",
                iconNameLength: (val) =>(val==null || val.trim().length <=100) || "图标名称不可大于100个字符",
            },
            AllPermission: [], //所有权限的平铺数据
            // 选择父级框信息
            accordion: true,
            clearable: true,
            valueTitle: this.AllpermissionName,
            // valueId: this.editPermissionDatas.pid,
            defaultProps: {
                children: "children",
                label: "permissionName",
            },
        };
    },
    components: {},
    computed: {
        ...mapState([
            'permissionType', //资源类型
        ]),
        console() {
            return console
        },
        editPermissionDatas: {
            get() {
                return this.editPermissionData;
            },
            set(v) {
                this.$emit("update:editPermissionData", v);
            },
        },
        // 让父级角色名称与父组件形成双向绑定
        userpermissionName: {
            get() {
                return this.AllpermissionName;
            },
            set(v) {
                this.$emit("update:AllpermissionName", v);
            },
        },
        editusermodel: {
            get() {
                return this.usermodel;
            },
            set(v) {
                this.$emit("update:usermodel", v);
                console.log("set editusermodel",v)
            },
        },
    },

    watch: {},
    methods: {
        //关闭时对话框是触发
        quxiao() {
            // this.valueId = "";
            this.editPermissionDatas.pid = ''
            this.userpermissionName = "";
            this.$emit("closedialog");
            this.$refs.myForm.resetValidation();
        },
        addPermissionsList() {
            this.$refs.myForm.validate().then(async (outcome) => {
                if (outcome) {
                    try {
                        const { data: res } = await Editpermission({
                            permissionName:
                                this.editPermissionDatas.permissionName,
                            permissionType: this.editusermodel.key,
                            permissionValue:
                                this.editPermissionDatas.permissionValue,
                            requestUrl: this.editPermissionDatas.requestUrl,
                            // pid: this.valueId,
                            pid: this.editPermissionDatas.pid,
                            id: this.editPermissionDatas.id,
                            iconName: this.editPermissionDatas.iconName,
                        });
                        console.log(res);
                        if (res.code === 20000) {
                            this.$emit("editpermissionssuccess"); // 表格刷新页面
                            this.$emit("closedialog"); // 关闭对话框
                            notifys('positive',res.message)
                        } else {
                            notifys('negative',res.message)
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            });
        },
        //节点被点击时回调
        handleNodeClick(node) {
            console.log(node);
            this.userpermissionName = node.permissionName;
            // this.valueId = node.id;
            this.editPermissionDatas.pid = node.id
        },
        //点击清空按钮时触发
        clearHandle() {
            // this.valueId = 0;
            this.editPermissionDatas.pid = 0
            this.userpermissionName = "";
        },
        visiblechange() {
            this.$emit("editclearHandles");
        },
    },
};
</script>

<style scoped>
.adduser {
    width: 21.875rem;
}
.add {
    width: 100%;
    margin-left: 0;
    margin-top: 0.1875rem;
}
.addbtn {
    display: flex;
    justify-content: flex-end;
}
.el-select {
    width: 100%;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
    height: auto;
    max-height: 17.125rem;
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
}
.el-select-dropdown__item.selected {
    font-weight: normal;
}
ul li >>> .el-tree .el-tree-node__content {
    height: auto;
    padding: 0 1.25rem;
}
.el-tree-node__label {
    font-weight: normal;
}
.el-tree >>> .is-current .el-tree-node__label {
    color: #409eff;
    font-weight: 700;
}
.el-tree >>> .is-current .el-tree-node__children .el-tree-node__label {
    color: #606266;
    font-weight: normal;
}
</style>
