<template>
  <div id="Permission" class="q-pa-md q-gutter-sm">
    <!-- 面包屑 -->
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
      <q-breadcrumbs-el label="权限管理" icon="iconfont icon-quanxian" />
      <q-breadcrumbs-el label="资源管理" icon="iconfont icon-ziyuanguanli" to="/permission/list" />
    </q-breadcrumbs>
    <!-- 搜索卡片区 -->
    <q-card class="my-card">
      <q-card-section>
        <div class="row">
            <div class="col-9 search">
              <q-input outlined :dense='true' class='operation' style="width: 15.625rem;" v-model="queryData.permissionName" label="资源名称" />
              <q-btn
                :disable='Permissionslist.filter(item=>item==="permission.list").length>0?false:true'
                class='operation'
                color="blue-6"
                label="重置"
                @click="chongzhi"
              />
              <q-btn
                :disable='Permissionslist.filter(item=>item==="permission.list").length>0?false:true'
                class='operation'
                color="blue-6"
                label="搜索"
                @click="getPermissionList()" 
              />
            </div>
            <div class="col addbtn">
              <q-btn class='operation'
                color="blue-6"
                label="新增资源"
                @click="addPermissionFun" 
              />
            </div>
        </div>
      </q-card-section>
    </q-card>
    <!-- 总卡片区 -->
    <q-card class="my-card">
      <!-- 搜索框区域 -->
      <q-card-section>
          <el-table
            :data="permissionlist"
            style="width: 100%;margin-bottom: 20px;"
            row-key="id"
            stripe
            header-align='center'
            :tree-props="{children: 'children'}"
            >
            <el-table-column prop="permissionName" label="资源名称" ></el-table-column>
            <el-table-column align='center' prop="permissionType" label="资源类型" >
              <template slot-scope="{row}">
                <q-chip outline square  :color="permissionTypeColor[row.permissionType]" text-color="white">
                  {{
                    (permissionType.filter(item=>item.key == row.permissionType).pop() || {} ).value
                  }}
                </q-chip>
              </template>
            </el-table-column>
            <el-table-column align='center' prop="permissionValue" label="资源权限值" ></el-table-column>
            <el-table-column align='center' prop="pname" label="父级资源名称" ></el-table-column>
            <el-table-column align='center' prop="date" label="操作"  width="300">
              <template slot-scope="scope">
                  <!-- 编辑 -->
                  <q-btn
                   :disable='Permissionslist.filter(item=>item==="permission.edit").length>0?false:true'
                   class="czbtn"
                   size="sm"
                   color="blue"
                   icon="iconfont icon-Edit"
                   @click='editpermission(scope.row)'
                  ></q-btn>
                  <!-- 删除 -->
                  <q-btn
                   :disable='Permissionslist.filter(item=>item==="permission.delete").length>0?false:true'
                    size="sm"
                   color="red-6"
                   icon="iconfont icon-ashbin"
                   @click='delpermission(scope.row)'
                  ></q-btn>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <!-- <elementPagination :total='total' :queryData='queryData' @getList='getPermissionList()' @changepageSize='changepageSize()' @changePage='changePage()'/> -->
          
      <!-- 列表区域结束 -->
      </q-card-section>
    </q-card>

    <!-- 新增资源对话框组件 -->
    <el-dialog :visible.sync="addconfirm" width="40%" title="新增资源" :close-on-click-modal='false' @close="dialogclose">
      <permissionadd 
      ref="newForm"
      :addPermissions='addPermissions'
      :permissionlist="permissionlist"
      :defaultExpandedKey='defaultExpandedKey'
      :addmodel.sync='addmodel'
      @addconfirm='dialogclose' 
      @addpermissionssuccess='getPermissionList()' 
      @closedialog='addconfirm = false'
      @clearHandles='clearHandle()'
      />
    </el-dialog>
    <!-- 查看资源信息对话框组件 -->
    <el-dialog :visible.sync="editconfirm" width="40%" title="编辑资源" :close-on-click-modal='false' @close="editclose">
      <permissionedit 
      ref="editForm"
      :permissionlist="permissionlist"
      :editPermissionData.sync='editPermissionData' 
      :defaultExpandedKey='defaultExpandedKey'
      :AllpermissionName.sync='AllpermissionName'
      :usermodel.sync='usermodel'
      @editclearHandles='clearHandle()'
      @editpermissionssuccess='getPermissionList()' 
      @closedialog='editconfirm = false'
      />
    </el-dialog>
  </div>
</template>

<script>
import permissionadd from '@/components/permissions/Addpermissions.vue' // 添加资源组件
import permissionedit from '@/components/permissions/Editpermission.vue' // 修改资源组件
import elementPagination from '@/components/ElementUI/elementPagination.vue' // 分页组件
import { makeDebounce } from "@/utils/debounce.js";

import { permissionList,Delpermission,Checkpermission } from '@/api/permissions.js'; // 获取接口
import { mapState } from "vuex"
import { notifys } from '@/api/components/Notify.js'


export default {
  created(){
    this.getPermissionList()
  },
  data() {
    return {

      permissionTypeColor:{
        0:'primary',
        1:'teal',
        2:'primary',
      },
      // 获取资源列表分页
      queryData: {
        paginationid:'',
        permissionName: '',
        // 当前页码
        page: 1,
        // 每页显示多少条
        pageSize: 8
      },
      total: 0,

      defaultExpandedKey:[], //设置选项框展开默认值
      
      // 新增对话框信息
      addPermissions:{
        permissionName:'',
        permissionValue:'',
        requestUrl:'',
        pid:0,
        valueTitle:'',
        iconName:'', //图标名称
      },
      // 储存列表信息
      permissionlist: [],
      AllPermission:[], // 平铺的列表信息
      AllpermissionName:'', // 父级id名称
      addmodel:'',
      usermodel:null,

      checkPermissionData: {}, // 查看用户信息
      editPermissionData:{}, // 修改用户信息

      addconfirm: false, // 控制新增资源弹窗
      // checkconfirm: false, // 控制查看资源
      editconfirm: false
    };
  },
  components:{
    permissionadd,
    permissionedit,
    elementPagination // 分页组件
  },
  computed:{
    ...mapState([
      'Permissionslist', // 权限词典
      'permissionType', //资源类型
    ])
  },
  watch:{
  },
  methods: {

  // 获取资源列表
  async getPermissionList(){
    const { data:res } = await permissionList({
      permissionName: this.queryData.permissionName,
    })
    console.log(res);
    this.permissionlist = res.data.permissions
    // this.total = res.data.permissions.length // 获取所有条数
    // console.log(res);
    this.treeToArray(this.permissionlist)
    // console.log(this.AllPermission);
  },

  // 利用递归函数 将树状资料全部平铺
  treeToArray (tree) {
    let arr= [];
    const expanded = datas => {
        if (datas&& datas.length > 0){
            datas.forEach(e => {
                arr.push(e);
                expanded(e.children);
            })
        }
    };
    expanded(tree);
    return this.AllPermission=arr;
  },
  // 点击重置
  chongzhi:makeDebounce(function(){
    this.queryData.permissionName=''
    this.queryData.paginationid=''
    this.getPermissionList()
  },200),
  // 点击新增资源
  addPermissionFun(){
    this.addconfirm = true
    this.defaultExpandedKey =[]
  },
  //关闭新增对话框时
  dialogclose(a){
    this.addconfirm=false
    this.addPermissions.permissionName='',
    this.addPermissions.permissionValue=''
    this.addPermissions.requestUrl=''
    this.addPermissions.valueTitle=''
    this.defaultExpandedKey =[]
    this.addmodel=''
    this.$refs.newForm.$refs.myForm.resetValidation();
    this.getPermissionList()
    
  },
  // 控制关闭窗口时 重置选择器
  clearHandle(){
    this.defaultExpandedKey =[]
    this.getPermissionList()
  },
  // 关闭修改弹窗时
  editclose(){
    this.editconfirm =false
    this.$refs.editForm.$refs.myForm.resetValidation()
  },
  // 修改资源信息
  async editpermission(row){
    this.editconfirm = true
    const { data:res } = await Checkpermission({id:row.id})
    console.log(res);
    // const options= [
    //   {
    //       label: 0,
    //       description: "菜单",
    //   },
    //   {
    //       label: 1,
    //       description: "按钮",
    //   },
    // ]

    this.editPermissionData = res.data.permission
    this.usermodel = this.permissionType.filter(item => item.key == this.editPermissionData.permissionType).pop();

    // 获取父级名称
    this.AllpermissionName=''
    const tickeds = this.editPermissionData.pid // 获取到所点击项 的父项id值
    const AllGroups = this.AllPermission // 获取平铺列表数据
    console.log(tickeds);
    AllGroups.forEach(Gname=>{
      if(tickeds===Gname.id){
        return this.AllpermissionName = Gname.permissionName
      }
    })
    // console.log(this.AllpermissionName);

  },
  // 删除资源信息
  async delpermission(row){
    console.log(row);
    this.$q.dialog({
      title: '删除',
      message: `你确定要删除资源:${row.permissionName}吗?`,
      ok: { label: '确定',color: 'red',},
      cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
            const {data:res} = await Delpermission({id:row.id})
            console.log(res);
            if(res.code===20000){
              this.getPermissionList()
              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
          }catch (e){
              console.log(e);
          }
      })
  },
  changepageSize(val){
    this.queryData.pageSize = val
  },
  changePage(val){
    this.queryData.page = val
  }
  // 点击分页按钮时触发
  // onRequest (props) {
  //     // console.log(props);
  //     const { page, rowsPerPage, sortBy, descending } = props.pagination
  //     this.pagination.page = page  // 点击之后改变页码值 传给this.pagination.page
  //     this.queryData.pageSize = rowsPerPage // 一页多少条改变是触发 获取数据给queryData.pageSize
  //     this.pagination.rowsPerPage = rowsPerPage
  //     this.getPermissionList() // 重新渲染页面
  // }

  },
};
</script>

<style lang='less' scoped>
  .search{
    display: flex;
    flex-direction: row ;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1.25rem;
    .searchbox{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  .addbtn{
    display: flex;
    flex-direction: row ;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1.25rem;
  }
  .operation{
    margin:0 .625rem;
  }
  .czbtn{
    margin-right: .75rem;
  }
</style>
